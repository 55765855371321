import { useCallback, useRef, useState } from 'react';
import useUploadFileRequest from './useUploadFileRequest';
import { clamp } from 'lodash';
import { OnCompletedPersistFile } from './usePersistFile';
import { toastErrorMessage } from 'utils/helpers';
import { PersistFileMutationOptions } from 'graphql/mutations/file/generated/PersistFile';
import {
  EnhancedFile,
  FilePayload,
  UploadStatus,
  enhanceFiles,
  updateFilesUploadStatus,
} from '../DroppedFiles.helpers';

type IDndParams = {
  iframeId?: string;
  tenantId?: string;
  persistFileRefetchQueries?: PersistFileMutationOptions['refetchQueries'];
  onCompletedPersistFile?: OnCompletedPersistFile;
};

const useDnd = (params: IDndParams) => {
  const { iframeId = '', tenantId = '', persistFileRefetchQueries, onCompletedPersistFile } = params || {};
  const filesRef = useRef<EnhancedFile[]>([]);
  const [, forceRerender] = useState(false);

  const onUploadProgress = useCallback((fileId: string, progressEvent: any) => {
    const { total, loaded } = progressEvent;

    const targetFileIdx = filesRef.current.findIndex(({ webClientFileId }) => webClientFileId === fileId);
    if (targetFileIdx === -1) return;

    filesRef.current[targetFileIdx].uploadPercentage = Math.round(clamp((loaded * 100) / total, 0, 100));
    forceRerender((a) => !a);
  }, []);

  const uploadFile = useUploadFileRequest({ onUploadProgress, persistFileRefetchQueries, onCompletedPersistFile });

  const onAddNewFiles = useCallback(
    async (values: { files: File[]; startIdx?: number; payload?: FilePayload; contentType?: string }) => {
      const { files, startIdx = 0, payload, contentType } = values;
      const droppedFiles = enhanceFiles(files, payload);

      try {
        filesRef.current.splice(startIdx, 0, ...droppedFiles);
        const { completedFileIds, rejectedFileIds, uploadFileIds } = await uploadFile({
          enhancedFiles: droppedFiles,
          iframeId,
          tenantId,
          contentType,
        });

        const restFiles = filesRef.current.filter(
          ({ webClientFileId }: EnhancedFile) => !completedFileIds.includes(webClientFileId),
        );

        const updatedFiles = updateFilesUploadStatus(restFiles, rejectedFileIds, UploadStatus.FAILED);
        filesRef.current = updatedFiles;
        forceRerender((a) => !a);
        return uploadFileIds;
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [uploadFile, iframeId, tenantId],
  );

  const handleUnsetFile = useCallback(
    (fileId: string) => {
      const file = filesRef.current.find((f) => f.webClientFileId === fileId);
      if (!file) return;
      file.controller.abort();
      const filteredFiles = filesRef.current.filter(({ webClientFileId }: EnhancedFile) => webClientFileId !== fileId);
      filesRef.current = filteredFiles;
      forceRerender((a) => !a);
    },
    [filesRef],
  );

  const handleRetryUpload = useCallback(
    async (fileId: string) => {
      const targetFileIdx = filesRef.current.findIndex(({ webClientFileId }) => webClientFileId === fileId);
      const file = filesRef.current[targetFileIdx].file;
      filesRef.current = filesRef.current.filter(({ webClientFileId }) => webClientFileId !== fileId);

      forceRerender((a) => !a);
      await onAddNewFiles({ files: [file], startIdx: targetFileIdx });
    },
    [filesRef, onAddNewFiles],
  );

  return {
    onUnsetFile: handleUnsetFile,
    onRetryUpload: handleRetryUpload,
    onAddNewFiles,
    droppedFiles: filesRef.current,
  };
};

export default useDnd;
