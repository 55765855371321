import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type FileFragment = {
  __typename?: 'File';
  _id: string;
  name: string;
  iframeId?: string | null;
  tenantId: string;
  sizeBytes: number;
  createdAt: any;
};

export type FileFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const FileFragmentDoc = gql`
  fragment File on File {
    _id
    name
    iframeId
    tenantId
    sizeBytes
    createdAt
  }
`;
