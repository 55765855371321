import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { FileInstanceLinkFragmentDoc } from '../../../fragments/file/generated/FileInstanceLink';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileInstancesQueryVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
}>;

export type FileInstancesQuery = {
  __typename?: 'Query';
  fileInstances: Array<{
    __typename?: 'FileInstanceLink';
    _id: string;
    instanceId?: string | null;
    instanceType: SchemaTypes.FileInstanceType;
    file: {
      __typename?: 'File';
      _id: string;
      name: string;
      iframeId?: string | null;
      tenantId: string;
      sizeBytes: number;
      createdAt: any;
    };
  }>;
};

export const FileInstancesDocument = gql`
  query FileInstances($iframeId: ID!) {
    fileInstances(iframeId: $iframeId) {
      ...FileInstanceLink
    }
  }
  ${FileInstanceLinkFragmentDoc}
`;

/**
 * __useFileInstancesQuery__
 *
 * To run a query within a React component, call `useFileInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileInstancesQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useFileInstancesQuery(
  baseOptions: Apollo.QueryHookOptions<FileInstancesQuery, FileInstancesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileInstancesQuery, FileInstancesQueryVariables>(FileInstancesDocument, options);
}
export function useFileInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileInstancesQuery, FileInstancesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileInstancesQuery, FileInstancesQueryVariables>(FileInstancesDocument, options);
}
export type FileInstancesQueryHookResult = ReturnType<typeof useFileInstancesQuery>;
export type FileInstancesLazyQueryHookResult = ReturnType<typeof useFileInstancesLazyQuery>;
export type FileInstancesQueryResult = Apollo.QueryResult<FileInstancesQuery, FileInstancesQueryVariables>;
export function refetchFileInstancesQuery(variables: FileInstancesQueryVariables) {
  return { query: FileInstancesDocument, variables: variables };
}
