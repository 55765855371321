import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportNCToDBMutationVariables = SchemaTypes.Exact<{
  fileId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ImportNCToDBMutation = { __typename?: 'Mutation'; importNCToDB: boolean };

export const ImportNCToDBDocument = gql`
  mutation ImportNCToDB($fileId: ID!) {
    importNCToDB(fileId: $fileId)
  }
`;
export type ImportNCToDBMutationFn = Apollo.MutationFunction<ImportNCToDBMutation, ImportNCToDBMutationVariables>;

/**
 * __useImportNCToDBMutation__
 *
 * To run a mutation, you first call `useImportNCToDBMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportNCToDBMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importNcToDbMutation, { data, loading, error }] = useImportNCToDBMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useImportNCToDBMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportNCToDBMutation, ImportNCToDBMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportNCToDBMutation, ImportNCToDBMutationVariables>(ImportNCToDBDocument, options);
}
export type ImportNCToDBMutationHookResult = ReturnType<typeof useImportNCToDBMutation>;
export type ImportNCToDBMutationResult = Apollo.MutationResult<ImportNCToDBMutation>;
export type ImportNCToDBMutationOptions = Apollo.BaseMutationOptions<
  ImportNCToDBMutation,
  ImportNCToDBMutationVariables
>;
