export enum AdminRoutePath {
  login = 'login',
  dashboard = 'dashboard',
  forgotPassword = 'forgotPassword',
  resetPassword = 'resetPassword',
  logout = 'logout',
  userManagement = 'userManagement',
  tenantsManagement = 'tenantsManagement',
  tenantManagement = 'tenantManagement',
  redeemInvitation = 'redeemInvitation',
  iframesManagement = 'iframesManagement',
  createIframe = 'createIframe',
  updateIframe = 'updateIframe',
  tenantIframe = 'tenantIframe',
  changeTenant = 'changeTenant',
  projectPdfRequests = 'projectPdfRequests',
  projects = 'projects',
  meinBauApiLogs = 'meinBauApiLogs',
  meinBauApiLog = 'meinBauApiLog',
  apiCallLogs = 'apiCallLogs',
  apiCallLog = 'apiCallLog',

  tenantDashboard = 'tenantDashboard',
  tenantIframesManagement = 'tenantIframesManagement',
  tenantUserManagement = 'tenantUserManagement',
  tenantMeinBauApiLogs = 'tenantMeinBauApiLogs',

  auditLogs = 'auditLogs',
  auditLog = 'auditLog',

  heatingDemandDataUpload = 'heatingDemandDataUpload',
}

export const routePaths: Record<AdminRoutePath, string> = {
  login: '/login',
  dashboard: '/dashboard',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  logout: '/logout',
  userManagement: '/user-management',
  tenantsManagement: '/tenants',
  tenantManagement: '/tenant/:tenantId',
  redeemInvitation: '/redeem-invitation/:invitationId',
  iframesManagement: '/iframes',
  createIframe: '/tenant/:tenantId/create-iframe',
  updateIframe: '/tenant/:tenantId/iframe/:iframeId/update',
  tenantIframe: '/tenant/:tenantId/iframe/:iframeId',
  changeTenant: '/change-tenant',
  projectPdfRequests: '/project-pdf-requests',
  projects: '/projects',
  meinBauApiLogs: '/meinbau-api-logs',
  meinBauApiLog: '/meinbau-api-log/:meinBauApiLogId',
  apiCallLogs: '/api-call-logs',
  apiCallLog: '/api-call-log/:apiLogId',

  tenantDashboard: '/tenant/:tenantId/dashboard',
  tenantIframesManagement: '/tenant/:tenantId/iframes',
  tenantUserManagement: '/tenant/:tenantId/user-management',
  tenantMeinBauApiLogs: '/tenant/:tenantId/meinbau-api-logs',

  auditLogs: '/audit-logs',
  auditLog: '/audit-log/:auditLogId',

  heatingDemandDataUpload: '/heating-demand-data-upload',
};
