import {
  ApiIntegrationNames,
  EquipmentType,
  IntegrationService,
  MultiFamilyHouseEnergyStandard,
  ProjectType,
  SolePlateOrBasement,
  TemplateName,
  WebhookEvent,
  WebhookTrigger,
} from 'graphql/types.generated';
import planningCategories from './planningCategoriesDe';
import { simpleDecrypt } from 'utils/helpers';
import { ApiLogsTabKeys } from 'pages/meinBauApiLogs/MeinBauApiLogsPages';
import { PredictedPriceIntegrations } from 'constants/types';

export default {
  translation: {
    yes: 'Ja',
    no: 'Nein',
    success: 'Erfolg',
    buttons: {
      next: 'Weiter',
      download: 'Download',
      finish: 'Fertig',
      submit: 'Submit',
      cancel: 'Cancel',
      confirm: 'Bestätigen',
      search: 'Suchen',
      send: 'Durchführen',
      back: 'Zurück',
      apply: 'Anwenden',
      refresh: 'Refresh',
      generate: 'Generieren',
      proceed: 'Weiter',
      save: 'Sichern',
      close: 'Schliessen',
      delete: '@Löschen',
      add: '@Add',
    },
    copiedToClipboard: 'In die Zwischenablage kopiert',
    locales: {
      en: 'English',
      de: 'German',
    },
    roles: {
      MEINBAU_ADMIN: 'MeinBau Administrator',
      TENANT_ADMIN: 'Tenant Administrator',
    },
    forms: {
      optionalLabel: '(optional)',
      selected: 'Ausgewählt -',
      searchByName: 'Name',
      noOptions: 'Keine Optionen',
    },
    table: {
      emptyDataMessage: '@Keine Daten gefunden',
    },
    auth: {
      email: 'Email',
      password: 'Passwort',
      firstName: 'Vorname',
      lastName: 'Nachname',
      role: 'Rolle',
      inviter: 'Einlader',
      repeatNewPassword: 'Neues Passwort wiederholen',
      passwordRules: 'Mindesten 8 Zeichen lang, davon 1 Groß- und Kleinbuchstabe und 1 Sonderzeichen',
      termsOfUseLabel: 'Ich akzeptiere die <termsOfUseUrl>Nutzungsbedingungen</termsOfUseUrl>',
      privacyPolicyLabel:
        'Informationen zur Datenverarbeitung finden Sie hier <privacyPolicyUrl>Datenschutz</privacyPolicyUrl>',
      forgotPassword: {
        questionOnLoginForm: 'Passwort vergessen?',
        goToLogin: 'Haben Sie bereits User und Passwort?',
        passwordResetEmailSent: `Prüfen Sie bitte Ihre Mails und klicken Sie auf den Passwort-Reset-Link. Falls sie keine erhalten haben, überprüfen Sie bitte auch den SPAM-Ordner der Mailbox.`,
      },
      resetPassword: {
        newPassword: 'Neues Passwort',
        repeatNewPassword: 'Neues Passwort wiederholen',
        resetPasswordBtn: 'Passswort rücksetzen',
        passwordChangedSuccessfully: 'Passwort wurde erfolgreich geändert',
      },

      otp: {
        code: 'OTP-Code',
        qrCodeInstructions: `
            <div>
              <strong>Schnellstartanleitung: Einrichten Ihrer Authentifizierungs-App für erweiterte Sicherheit:</strong>
              <ol>
                <li>Herunterladen: Gehen Sie in den App Store (App Store für iOS, Google Play Store für Android) und suchen Sie nach Ihrer gewählten Authentifizierungs-App (z.B. Google Authenticator oder Authy).</li>
                <li>Installieren: Laden Sie die App herunter und installieren Sie sie auf Ihrem Gerät.</li>
                <li>Öffnen: Öffnen Sie die App nach der Installation.</li>
                <li>Konto hinzufügen: Tippen Sie in der App auf das Pluszeichen (+), um ein neues Konto hinzuzufügen.</li>
                <li>QR-Code scannen: Wählen Sie "QR-Code scannen" und verwenden Sie Ihre Kamera, um den QR-Code von der Website oder dem Dienst zu scannen.</li>
                <li>Code eingeben: Geben Sie den zeitbasierten Verifizierungscode, den die App generiert, in die Website oder den Dienst ein.</li>
                <li>Zugriff: Verwenden Sie den Verifizierungscode aus der App, wenn Sie sich auf der Website oder dem Dienst anmelden.</li>
              </ol>
            </div>
          `,
      },
    },
    userManagement: {
      searchQuery: 'Filter nach Name und E-Mail',
      tabs: {
        meinbauAdmins: 'MeinBau Administratoren',
        tenantUsers: 'Tenant User',
        apiUsers: 'API User',
      },
      users: {
        name: 'Accounts',
        registeredAt: 'Registriert am',
        addTenantUserBtn: 'User hinzufügen',
        addTenantUserModal: {
          header: 'User zu Kunden hinzufügen',
          clientUsersField: 'Users',
        },
        updateUserAccount: {
          modalHeader: 'Account aktualisieren',
        },
        noUsersFound: {
          description: 'Aktuell sind noch keine User vorhanden...',
        },
        deleteUserFromTenant: {
          title: 'Löschen',
          message: 'Sind Sie sicher, dass der User von diesem Kunden gelöscht werden soll?',
          successfullyDeleted: 'Erfolgreich gelöscht',
        },
      },
      invitations: {
        title: 'Einladungen',
        role: 'Rolle',
        invitationDate: 'Datum der Einladung',
        draftOrSent: 'Entwurf oder gesendet',
        draft: 'Entwurf',
        sent: 'Gesendet',
        inviteUserBtn: 'User anlegen',
        modalHeader: 'User einladen',
        invitationSuccessfullySent: 'Einladung erfolgreich versendet',
        retryInvitation: 'Neu senden',
        branches: 'Filialen',
        inviter: 'Einlader',
        additionalMessage:
          'Hier können Sie dem Kunden eine individuelle Nachricht schreiben, die Teil der Einladungsmail sein wird (optional)',
        deleteInvitation: {
          title: 'Zurückziehen',
          message: 'Sind Sie sicher, dass Sie die Einladung für diese {{email}} Email löschen möchten?',
          invitationSuccessfullyDeleted: 'Einladung erfolgreich gelöscht',
        },
        noInvitationsFound: {
          description: 'Akutell sind noch keine Einladungen vorhanden',
        },
        form: {
          saveAsDraft: 'Zwischenspeichern',
          saveAndSend: 'Speichern & Einladung versenden',
        },
      },
    },
    tenantManagement: {
      baseData: {
        createdBy: 'Erstellt von ',
        updatedBy: 'Aktualisiert von ',
        archivedBy: 'Archiviert von ',
        releasedBy: 'Freigegeben von ',
      },
      tabs: {
        baseData: 'Grundinformation',
        updateTenantBtn: 'Mieter aktualisieren',
        suspendTenantBtn: 'Mieter aussetzen',
        releaseTenantBtn: 'Mieter freigeben',
        iframes: 'iframes',
        users: 'Benutzer',
      },
      alert: {
        releaseHeader: 'Mieter freigeben',
        suspendHeader: 'Mieter aussetzen',
        releaseTenantAlert: 'Sind Sie sicher, dass Sie den Tenant freigeben möchten?',
        suspendTenantAlert: 'Sind Sie sicher, dass Sie den Tenant aussetzen möchten?',
      },
      modal: {
        headerUpdate: 'Tenant aktualisieren',
        update: 'Aktualisieren',
        headerCreate: 'Tenant erstellen',
        create: 'Erstellen',
        companyNameLabel: 'Firmenname',
        close: 'Schließen',
      },
      active: 'Aktiv',
      archived: 'Archiviert',
    },
    tenants: {
      active: 'Aktiv',
      archived: 'Archiviert',
      createTenantBtn: 'Tenant erstellen',
      tableHeaders: {
        companyName: 'Firmenname',
        archived: 'Archiviert',
        status: 'Status',
        createdBy: 'Erstellt von',
        updatedBy: 'Aktualisiert von',
        statusChangedBy: 'Status geändert von',
      },
      noTenantsFound: {
        description:
          'Es wurden keine Mieter für Ihre Anfrage gefunden. Bitte versuchen Sie, sie hinzuzufügen oder Ihre Suchkriterien zu ändern.',
        title: 'Keine Mieter gefunden',
      },
      searchQuery: 'Nach Firmennamen filtern',
      status: {
        label: '@Nach Status filtern',
        all: '@Alle',
        archived: '@Archiviert',
        active: '@Aktiv',
      },
    },
    files: {
      deleteFileAlert: {
        title: 'Datei löschen',
        message: 'Möchten Sie diese Datei löschen?',
      },
      table: {
        headers: {
          filename: 'Dateiname',
        },
      },
    },
    dnd: {
      uploadAlertWarningMessage: 'Upload läuft - ein Schließen führt zu Datenverlust!',
      dropFileHere: 'Dateien hier einfügen',
      acceptableFileTypes: 'Folgende Dateitypen können hochgeladen werden - {{extensions}}',
      extensionIsNotAllowed:
        'Upload Error \n Es tut uns leid, aktuell ist dieser Dateityp nicht zulässig \n {{fileName}}',
      tooManyFiles: 'Sie können maximal nur {{maxFilesCount}} Dateien auf einmal hochladen!',
      maxFileSize: 'Maximale Dateigröße ist {{size}} mb',
      maxFileSizeError: 'Upload Error \n Maximale Dateigröße ist {{size}} mb \nFiles {{fileNames}} skipped',
      releaseToUpload: 'Zum Upload hier los lassen',
      uploadBtnLabel: 'Upload',
    },
    iframeAssets: {
      errorOccurredWhiteGettingFileInstances: 'Beim Abrufen von Dateiinstanzen ist ein Fehler aufgetreten',
      modal: {
        header: 'Bild auswählen',
        newFileInteractionDescription: 'Wählen Sie aus vorhandenen Dateien aus oder laden Sie eine neue hoch',
        existingFileInteractionDescription:
          'Entfernen Sie die vorhandene Datei, um eine neue hochzuladen oder auszuwählen',
        noFileSelected: 'Keine Datei ausgewählt',
        noFilesUploaded: 'Keine Dateien hochgeladen',
      },
      types: {
        EMAIL_LOGO: 'E-Mail-Logo',
        FAVICON_128: 'Favicon 128',
        UI_LOGO: 'UI-Logo',
        PDF_LOGO: 'PDF-Logo',
        FAVICON: 'Favicon',
        APPLE_TOUCH_ICON: 'Apple Touch Icon',
        FAVICON_32: 'Favicon 32',
        FAVICON_16: 'Favicon 16',
        SAFARI_PINNED_TAB: 'Safari-Pin-Reiter',
        ANDROID_CHROME_192: 'Android Chrome 192',
        ANDROID_CHROME_512: 'Android Chrome 512',
        IMG_HOME_PAGE: 'Bild Startseite',
        IMG_FIRST_STEP: 'Bild erster Schritt',
        IMG_SECOND_STEP: 'Bild zweiter Schritt',
        IMG_THIRD_STEP: 'Bild dritter Schritt',
        IMG_FOURTH_STEP: 'Bild vierter Schritt',
        IMG_FIFTH_STEP: 'Bild fünfter Schritt',
        PARTNERS_FIRST_SECTION_BG: 'Partner - Hintergrund erste Sektion',
        PARTNERS_FIRST_SECTION_LOGO: 'Partner - Logo erste Sektion',
        PARTNERS_SECOND_SECTION_BG: 'Partner - Hintergrund zweite Sektion',
        PARTNERS_SECOND_SECTION_LOGO: 'Partner - Logo zweite Sektion',
        PARTNERS_THIRD_SECTION_BG: 'Partner - Hintergrund dritte Sektion',
        PARTNERS_THIRD_SECTION_LOGO: 'Partner - Logo dritte Sektion',
      },
      table: {
        headers: {
          type: 'Typ',
          file: 'Datei',
        },
      },
    },
    webhook: {
      addWebhookButton: '@Add webhook',
      addWebhookModal: {
        header: '@Add webhook',
      },
      updateWebhookModal: {
        header: '@Update webhook',
      },
      deleteWebhookAlert: {
        title: '@Delete webhook',
        message: '@Are you sure, do you want to delete webhook?',
      },
      form: {
        trigger: '@Trigger',
        events: '@Events',
        url: 'Url',
        headers: '@Headers',
        triggerOptions: {
          [WebhookTrigger.pdfRequestSubmission]: '@PDF request submission',
        },
        eventOptions: {
          [WebhookEvent.transferPDF]: '@Transfer PDF',
          [WebhookEvent.transferJSON]: '@Transfer JSON',
        },
      },
      headers: {
        form: {
          name: '@Name',
          value: '@Value',
        },
        table: {
          name: '@Name',
          value: '@Value',
          emptyDataMessage: '@No headers found',
        },
        headerAlreadyExists: '@Header already exists',
      },
    },
    iframe: {
      active: 'Aktiv',
      archived: 'Archiviert',
      updateIframeBtn: 'Iframe aktualisieren',
      suspendIframeBtn: 'Iframe aussetzen',
      releaseIframeBtn: 'Iframe freigeben',
      integrationWithPriceHubbleModal: 'PriceHubble Integration',
      integrationWithSpregnetterModal: 'Spregnetter Integration',
      priceHubbleApiUsername: 'PriceHubble API Benutzername',
      priceHubbleApiPassword: 'PriceHubble API Passwort',
      integrate: 'Integrieren',
      close: 'Schließen',
      integratePriceHubbleIframeBtn: 'Mit PriceHubble integrieren',
      disablePriceHubbleIframeBtn: 'Integration deaktivieren',
      integratedWithPriceHubble: 'Mit PH integriert',
      notIntegratedWithPriceHubble: 'Nicht mit PH integriert',
      integrationWithSubmissionWebhookModal: 'Webhook-Einbindung für Übermittlung',
      submissionWebhookClientId: 'Client-ID',
      submissionWebhookUrl: 'URL',
      integrationWithProjectPdfWebhookModal: 'Webhook-Einbindung für Projekt-PDF',
      projectPdfWebhookClientId: 'Client-ID',
      projectPdfWebhookUrl: 'URL',
      copyLink: 'Iframe-Link kopieren',
      copiedToClipboard: 'Iframe-URL in die Zwischenablage kopiert',
      baseData: {
        iframeHtmlTitle: 'HTML title',
        projectTypes: 'Projekttypen',
        primaryColor: 'Hauptfarbe',
        pdfButtonColor: 'PDF-Schaltflächenfarbe',
        textColor: 'Textfarbe',
        domainWhiteListTypes: 'Domänen-Whitelist',
        headInjectionTags: 'Einzufügende Tags',
        releasedBy: 'Freigegeben von',
        archivedBy: 'Archiviert von',
        projectTypeNotSpecified: 'Projekttyp ist nicht angegeben',
        domainWhiteListTypesNotSpecified: 'Domänen-Whitelist ist nicht angegeben',
        headInjectionTagsNotSpecified: 'Einzufügende Tags sind nicht angegeben',
        requestProjectPdfConfig: {
          emailFrom: 'PDF E-Mail',
          subject: 'Betreff der PDF-E-Mail',
          message: 'Nachricht der PDF-E-Mail',
        },
        projectPdfConfig: {
          pdfWebsiteUrl: 'URL der PDF-Website',
        },
        privacyPolicyUrl: 'URL zur Datenschutzerklärung',
        termsOfUseUrl: 'URL zu den Nutzungsbedingungen',
      },
      alert: {
        releaseHeader: 'Iframe freigeben',
        suspendHeader: 'Iframe aussetzen',
        releaseIframeAlert: 'Sind Sie sicher, dass Sie das Iframe freigeben möchten?',
        suspendIframeAlert: 'Sind Sie sicher, dass Sie das Iframe aussetzen möchten?',
        disableIntegrationHeader: 'Prognostizierter Preis Integration deaktivieren',
        disableIntegrationAlert:
          'Sind Sie sicher, dass Sie die Integration deaktivieren möchten? Dies löscht aktuelle Informationen über den API-Benutzernamen und das Passwort.',
        disableSubmissionWebhookHeader: 'Deaktivieren der Übermittlung-Webhook-Integration',
        disableSubmissionWebhookAlert:
          'Sind Sie sicher, dass Sie die Übermittlung-Webhook-Integration deaktivieren möchten?',
        disableProjectPdfWebhookHeader: 'Deaktivieren der Projekt-PDF-Webhook-Integration',
        disableProjectPdfWebhookAlert:
          'Sind Sie sicher, dass Sie die Projekt-PDF-Webhook-Integration deaktivieren möchten?',
        disableGTMIntegrationHeader: 'GTM-Integration deaktivieren',
        disableGTMIntegrationAlert:
          'Sind Sie sicher, dass Sie die Integration deaktivieren möchten? Dies löscht den aktuellen GTM-Schlüssel.',
        removeApiIntegrationHeader: '{{name}}-Integration deaktivieren',
        disableApiIntegrationAlert:
          'Möchten Sie die Integration wirklich entfernen? Dadurch werden die aktuellen Informationen zur API-Client-ID und zum Client-Geheimnis gelöscht.',
      },
      countries: {
        de: 'DE',
        at: 'AT',
      },
      form: {
        locationCorrectionFactorEnabled: 'Standortbasierter Korrekturfaktor aktivieren',
        isDisabledLabel: 'Gefährlich! Schalte diesen Schalter um, um das iFrame zu aktivieren oder zu deaktivieren!',
        htmlTitle: 'HTML Title',
        contactEmail: 'Kontakt-E-Mail',
        headerUpdate: 'Iframe @aktualisieren',
        headerCreate: 'Iframe @erstellen',
        nameLabel: 'Name',
        primaryColorLabel: 'Hauptfarbe',
        pdfButtonColorLabel: 'PDF-Schaltflächenfarbe',
        textColorLabel: 'Textfarbe',
        showAIHintLabel: 'Show AI Hints',
        projectTypeLabel: 'Projekttypen',
        domainWhiteList: 'Domänen-Whitelist',
        headInjectionTags: 'HTML-Metatags',
        update: 'Aktualisieren',
        close: 'Schließen',
        create: 'Erstellen',
        pdfConfig: 'PDF-Konfiguration',
        pdfEmailConfig: 'PDF-E-Mail-Konfiguration',
        pdfEmailConfigEmail: 'PDF-E-Mail',
        pdfEmailConfigEmailCC: 'CC E-mail',
        useImplementationStartPoint: 'Implementierungsstartpunkt einschließen?',
        usePhoneNumber: 'Benutzer-Telefonnummer einschließen?',
        pdfEmailConfigSubject: 'Betreff der PDF-E-Mail',
        pdfEmailConfigMessage: 'Nachricht der PDF-E-Mail',
        pdfWebsiteUrl: 'URL der PDF-Website',
        privacyPolicyUrl: 'URL zur Datenschutzerklärung',
        termsOfUseUrl: 'URL zu den Nutzungsbedingungen',
        pdfDownloadEnabledViaPublicRoute: 'Erlaubt PDF-Downloads über öffentliche Route',
        newPdfRequestNotification: 'Neue Benachrichtigung für PDF-Anforderung',
        projectTemplate: '@Project template',
        country: 'das Land',
        integrationServices: '@Integration services',
        integrationServiceOptions: {
          solarApi: '@Solar API',
          propertyValuation: '@Property valuation',
          aiHints: '@AI Hints',
        } as Record<IntegrationService, string>,
      },
      templates: {
        [TemplateName.hiil]: simpleDecrypt('Hiil'),
        [TemplateName.vsdundvvhErghqvhh]: simpleDecrypt('Vsdundvvh Erghqvhh'),
        [TemplateName.vsdundvvhGxuhq]: simpleDecrypt('Vsdunvssv Güuhq'),
        [TemplateName.vsdundvvhPrqfkhqjodgedfk]: simpleDecrypt('Vsdundvvh Pöqfkhqjodgedfk'),
        [TemplateName.vdqlhuxqjvuhfkqhuDW]: simpleDecrypt('Vdqlhuxqjvuhfkqhu DW'),
        [TemplateName.phlqedx]: simpleDecrypt('PhlqEdx'),
        [TemplateName.uhg]: simpleDecrypt('Uhg'),
        [TemplateName.eoxh]: simpleDecrypt('Eoxh'),
        [TemplateName.kbsoQrh]: simpleDecrypt('KBSO QRH'),
        [TemplateName.hiilIxoo]: simpleDecrypt('Hiil Ixoo'),
        [TemplateName.rehuedqn]: simpleDecrypt('Rehuedqn'),
        [TemplateName.default]: 'Standard',
      } as Record<TemplateName, string>,
      integrations: {
        apiIntegrations: 'API-Integrationen',
        predictedPriceIntegration: 'Predicted price integration',
        username: 'Benutzername',
        predictedPriceNotConnected: 'Iframe nicht mit der API für den prognostizierten Preis verbunden',
        predictedPriceInputLabel: 'Wählen Sie die API aus, die Sie integrieren möchten.',
        integratedAPI: 'Integrierte API',
        [PredictedPriceIntegrations.priceHubble]: 'Price Hubble',
        [PredictedPriceIntegrations.spregnetter]: 'Sprengnetter AT API',
        gtmConfiguration: 'GTM-Integration',
        gtmNotConnected: 'Iframe nicht mit GTM integriert',
        gtmKey: 'GTM-Schlüssel',
        serviceName: 'Dienstname',
        clientId: 'Kunden-ID',
        secret: 'Client-Geheimnis',
        [ApiIntegrationNames.twilio]: 'Twilio',
      },
      tabs: {
        translations: '@Translations',
        baseData: 'Iframe-Basisdaten',
        integrations: 'Integrationen',
        assets: 'Assets',
        projectPdfRequests: 'Iframe PDF-Anfragen',
        projects: 'Projekte',
        generalCo2Report: 'Allgemeiner CO2-Bericht',
        co2ReportByProject: 'CO2-Bericht nach Projekt',
        preview: 'Iframe preview',
        fuelMatrixConfiguration: 'Konfiguration der Kraftstoffmatrix',
        categoryConfiguration: 'Kategoriekonfiguration',
        parameters: 'Parameter',
        energy: 'Energiekoeffizienten',
      },
      gtm: {
        modal: {
          header: 'GTM-Integrationsfenster',
          gtmKey: 'GTM-Schlüssel',
        },
      },
    },
    co2Reports: {
      searchQuery: 'Nach Projektnamen filtern',
      noReportsFound: {
        description: 'Es gibt keine Projekte mit CO2-Berichten',
      },
      tableHeaders: {
        co2Saving: 'CO2-Einsparungen',
        projectsCount: 'Projektanzahl',
        renovationName: 'Renovierungsname',
        name: 'Projektname',
      },
      renovations: {
        newWindows: 'Neue Fenster',
        newWindowsCostSavings: 'Einsparungen durch neue Fenster',
        ceilingTopInsulation: 'Deckendämmung oben',
        ceilingTopInsulationCostSavings: 'Einsparungen durch Deckendämmung oben',
        ceilingBasementInsulation: 'Deckendämmung Keller',
        ceilingBasementInsulationCostSavings: 'Einsparungen durch Deckendämmung Keller',
        facadeInsulation: 'Fassadendämmung',
        facadeInsulationCostSavings: 'Einsparungen durch Fassadendämmung',
        domesticVentilation: 'Hauslüftung',
        domesticVentilationCostSavings: 'Einsparungen durch Hauslüftung',
        solarPowerSystem: 'Solarenergieanlage',
        solarPowerSystemCostSavings: 'Einsparungen durch Solarenergieanlage',
        heatingSystem: 'Heizungssystem',
        heatingSystemCostSavings: 'Einsparungen durch Heizungssystem',
      },
    },
    changeTenant: {
      welcomeBack: 'Willkommen zurück, {{firstName}}!',
      selectTenant: 'Für welchen Kunden möchten Sie sich anmelden?',
      searchByTenantName: 'Name des Kunden',
    },
    iframes: {
      tableHeaders: {
        name: 'Name',
        projectTypes: 'Projekttyp',
        archived: 'Status',
      },
      searchQuery: 'Nach Name filtern',
      createIframeBtn: 'Iframe erstellen',
      noIframesFound: {
        description:
          'Es wurden keine Iframes für Ihre Anfrage gefunden. Bitte versuchen Sie, sie hinzuzufügen oder Ihre Suchkriterien zu ändern',
      },
    },
    projectPdfRequests: {
      searchQuery: 'Nach Projektnamen filtern',
      userNameNotProvided: 'Name wurde kein',
      tableHeaders: {
        userName: 'Vor- und Zuname',
        name: 'Projektname',
        email: 'E-Mail',
        phoneNumber: 'Telefonnummer',
        clientIp: 'Client-IP',
        pdf: 'PDF',
        createdAt: 'Hergestellt in',
      },
      noProjectPdfRequestsFound: {
        description:
          'Es wurden keine PDF-Anfragen für Ihre Anfrage gefunden. Bitte versuchen Sie, sie hinzuzufügen oder Ihre Suchkriterien zu ändern',
      },
    },
    projectTypes: {
      all: 'Alle',
      [ProjectType.newBuilding]: 'New building',
      [ProjectType.renovatingHouse]: 'Renovation house',
      [ProjectType.multiFamilyHouse]: 'Multi Family House',
    },
    equipmentTypes: {
      superior: 'Superior',
      premium: 'Premium',
      standard: 'Standard',
    } as Record<EquipmentType, string>,
    projects: {
      searchQuery: 'Nach Projektnamen filtern',
      projectEditModal: 'Edit project',
      tableHeaders: {
        name: 'Projektname',
        projectType: 'Projekttyp',
        price: 'Preis',
        clientIP: 'Client-IP',
        pdf: 'PDF',
        excel: 'EXCEL',
        projectOverviewPage: '@Project overview',
        createdAt: 'Hergestellt in',
      },
      projectType: {
        label: 'Nach Projekttyp filtern',
        renovatingHouse: 'Hausrenovierung',
      },
      noProjectsFound: {
        description:
          'Es wurden keine Projekte für Ihre Anfrage gefunden. Bitte versuchen Sie, sie hinzuzufügen oder Ihre Suchkriterien zu ändern',
      },
      downloadReportOfAllProjects: 'Xlsx herunterladen',
    },
    meinBauApiLogs: {
      tabs: {
        [ApiLogsTabKeys.apiLogsChart]: 'Diagramm',
        [ApiLogsTabKeys.apiLogsTable]: 'Tabelle',
      },
      chart: {
        title: 'API-Aufrufe pro Mandant',
        noDataTitle: 'Keine API-Aufrufe pro Mandant',
        noDataDescription: 'Derzeit gibt es keine API-Aufrufe. Es scheint, dass diese API noch nicht verwendet wurde.',
      },
      tableHeaders: {
        appName: 'Anwendungsname',
        useCase: 'Anwendungsfall',
        ip: 'IP',
        method: 'HTTP Methode',
        createdAt: 'Erstellt am',
        status: 'Status',
      },
    },
    meinBauApiLog: {
      meinBauApiLogPageTitle: 'MeinBau API-Log Detailseite',
      titles: {
        baseData: 'Allgemeine Informationen des API-Logs',
        input: 'Eingabedaten',
        result: 'Ergebnis der Anfrage',
        copy: 'Kopieren',
      },
      baseData: {
        appName: 'Anwendungsname',
        ip: 'IP',
        httpMethod: 'HTTP-Methode',
        createdAt: 'Zeitpunkt des Eintrags',
        appNameNotSpecified: 'Anwendungsname nicht angegeben',
      },
      copiedToClipboard: 'Daten erfolgreich in die Zwischenablage kopiert',
    },
    apiLogs: {
      filter: {
        apiName: {
          label: 'API-Name auswählen',
          all: 'Alle',
          effi: 'Effi',
          priceHubble: 'PriceHubble',
        },
      },
      tableHeaders: {
        apiName: 'API-@Name',
        eventType: 'Ereignistyp',
        statusCode: 'Status',
        url: 'URL',
        data: 'Daten',
        details: 'Details',
        method: 'Methode',
        params: 'Parameter',
      },
    },
    apiLog: {
      apiLogPageTitle: 'API-Log Seite',
      titles: {
        baseData: 'Grunddaten',
      },
      baseData: {
        apiName: 'API-Name',
        eventType: 'Ereignistyp',
        method: 'HTTP-Methode',
        statusCode: 'Statuscode',
        url: 'URL',
        data: 'Daten',
        params: 'Parameter',
        eventTypeNotSpecified: 'Ereignistyp nicht angegeben',
        statusCodeNotSpecified: 'Statuscode nicht angegeben',
        urlNotSpecified: 'URL nicht angegeben',
        dataNotSpecified: 'Daten nicht angegeben',
        paramsNotSpecified: 'Parameter nicht angegeben',
      },
    },
    projectReports: {
      button: 'Generierte Projektberichte',
      generationHasBegun: 'Die Generierung von Excel hat begonnen',
      modal: {
        title: 'Projektberichte',
      },
      tableHeaders: {
        createdAt: 'Erstellt am',
        rangeStartDate: 'Von',
        rangeEndDate: 'Bis',
        status: 'Status der Generierungsanforderung',
        iframeName: 'Iframe name',
      },
      error: {
        failedToLoad: 'Fehler beim Laden der Berichte',
      },
      errorModalHeader: 'Dateiberichterstellung',
    },
    dashboardInfo: {
      rangePicker: {
        lastMonthDateRange: 'Letzter Monat',
        thisMonthDateRange: 'Aktueller Monat',
        allTimeDateRange: 'Gesamte Zeit',
        startTimeDateRange: 'Startdatum Zeitraum',
        endTimeDateRange: 'Enddatum Zeitraum',
        applyRange: 'Anwenden',
        startDate: 'Startdatum',
        endDate: 'Enddatum',
      },
      tenantsCount: 'Alle erstellten Tenants',
      usersCount: 'Alle erstellten/eingeladenen Benutzer',
      projectsCount: 'Alle erstellten Projekte',
      pdfRequestsCount: 'Alle PDF-Anfragen',
      projectPerTenant: 'Projekte pro Tenant',
      pdfRequestPerTenant: 'PDF-Anfragen pro Tenant',
      noDataTextProjects: {
        title: 'Keine Tenants mit Projekten',
        description: 'Es gibt keine Tenants mit erstellten Projekten.',
      },
      noDataTextPdfRequests: {
        title: 'Keine Tenants mit PDF-Anfragen',
        description: 'Es gibt keine Tenants mit erstellten PDF-Anfragen.',
      },
    },
    tenantDashboardInfo: {
      projectPerIframe: 'Projekte pro Iframe',
      pdfRequestPerIframe: 'PDF-Anfragen pro Iframe',
      noDataTextProjects: {
        title: 'Keine Iframes mit Projekten',
        description: 'Es gibt keine Iframes mit erstellten Projekten.',
      },
      noDataTextPdfRequests: {
        title: 'Keine Iframes mit PDF-Anfragen',
        description: 'Es gibt keine Iframes mit erstellten PDF-Anfragen.',
      },
    },
    applicationManagement: {
      name: 'Anwendungen',
      tableHeaders: {
        appName: 'Anwendungsbenutzername',
        tenantName: '@Associated tenant',
        createdBy: '@Created by',
        createdAt: '@Created at',
        archivedBy: '@Archived by',
      },
      searchQuery: 'Suche nach Anwendungsbenutzernamen',
      modal: {
        header: 'Anwendungsbenutzer erstellen',
        appName: 'Anwendungsbenutzername',
        close: 'Schließen',
        create: 'Erstellen',
        applicationId: 'X-Client-ID',
        password: 'Geheimnis',
        copiedToClipboard: 'In die Zwischenablage kopiert',
        tenant: {
          label: 'Tenant auswählen',
          noTenant: 'Ohne Tenant',
        },
      },
      alert: {
        title: '@Archive application user?',
        message: '@This action will archive application permanently. Are you sure you want to proceed?',
      },
      toast: {
        applicationArchived: '@Application: {{appName}} archived successfully',
      },
      noApplicationsFound: {
        description:
          'Es gibt keine Anwendungsbenutzer, versuchen Sie, einen zu erstellen oder die Suchanfrage zu ändern',
      },
      createApplicationAccount: 'Anwendungsbenutzer erstellen',
    },
    errors: {
      invalidEmail: 'Muss eine gültige E-Mail-Adresse sein.',
      requiredField: 'Fill in this field',
      chooseOne: 'Please choose one',
      passwordIsTooShort: 'Passwort zu kurz',
      passwordsMustMatch: 'Passwörter stimmen nicht überein',
      min: 'Bitte mindestens {{min}} Zeichen eingeben',
      invalidDomain: 'Domain ist ungültig',
      invalidUrl: 'URL ist ungültig',
    },
    translations: {
      resultPageConfigurationUpdated: '@Result page configuration successfully updated',
      updateTranslations: '@Save Result page configuration',
      planSection: {
        isEnabled: '@Plan Sections',
      },
      equivalentsSection: {
        isEnabled: '@Equivalents Sections',
      },
      totalCostSection: {
        isEnabled: '@Total Cost Sections',
      },
      investmentsPayOfSection: {
        isEnabled: '@Investments Pay Off Sections',
      },
      headerSection: {
        isEnabled: '@Header Section',
        header: {
          en: '@Header English',
          de: '@Header German',
        },
      },
      firstPartnerSection: {
        isEnabled: '@First Partner Section',
        header: {
          en: '@Header English',
          de: '@Header German',
        },
        content: {
          en: '@Content English',
          de: '@Content German',
        },
        pdfButton: {
          en: '@PDF Button English',
          de: '@PDF Button German',
        },
      },
      secondPartnerSection: {
        isEnabled: '@Second Partner Section',
        header: {
          en: '@Header English',
          de: '@Header German',
        },
        content: {
          en: '@Content English',
          de: '@Content German',
        },
        pdfButton: {
          en: '@PDF Button English',
          de: '@PDF Button German',
        },
      },
    },
    auditLog: {
      titles: {
        baseData: 'Grunddaten',
        payload: 'Ereignis-Payload',
      },
      baseData: {
        name: 'Audit-Log-Name',
        entity: 'Entität',
        tenantId: 'Mandanten-ID',
        tenantIdIsNotSpecified: 'Mandanten-ID wurde nicht angegeben',
        createdBy: 'Erstellt von',
        meta: {
          clientIP: 'Client-IP',
          country: 'Land',
          countryIsNotSpecified: 'Land wurde nicht angegeben',
          domain: 'Domäne',
          utmParams: 'Urchin Tracking Module (UTM) parameter',
          utmParamsIsNotSpecified: 'UTM wurde nicht angegeben',
        },
      },
      payload: {
        tableHeaders: {
          label: 'Feldname',
          value: 'Feldwert',
        },
      },
      copy: 'Als JSON gesamtes Payload kopieren',
      auditLogPageTitle: 'Audit-Log-Seite',
      payloadIsNotProvided: 'Payload wurde nicht angegeben',
      copiedToClipboard: 'In die Zwischenablage kopiert',
      valueCopiedToClipBoard: 'Wert von {{label}} in die Zwischenablage kopiert',
      helper: 'Um einen bestimmten Wert zu kopieren, klicken Sie auf die Zeile der Tabelle',
    },
    auditLogs: {
      searchQuery: 'Nach Name filtern',
      tenantQuery: 'Nach Mieter filtern',
      startDateQuery: 'Nach Startdatum filtern',
      endDateQuery: 'Nach Enddatum filtern',
      tableHeaders: {
        name: 'Name',
        entity: 'Betroffene Entität',
        createdBy: 'Erstellt von',
        createdAt: 'Zeitpunkt der Datensatzerstellung',
      },
    },
    fuelMatrix: {
      tableHeaders: {
        itemName: 'Name',
        input: 'Eingabe',
        unit: 'Einheit',
        avgPricePerUnit: 'Durchschnittlicher Preis pro Einheit',
        conversionFactor: 'Umrechnungsfaktor',
        resultInKwh: 'Ergebnis in kWh',
        avgPricePerKwh: 'Durchschnittlicher Preis pro kWh',
      },
      tableRowItems: {
        heatingOil: 'Heizöl',
        naturalGas: 'Erdgas',
        coal: 'Kohle',
        firewood: 'Brennholz',
        woodChips: 'Holzspäne',
        woodPellets: 'Holzpellets',
        electricityUsed: 'Verbrauchter Strom',
        electricityProduced: 'Produzierter Strom',
        districtHeating: 'Fernwärme',
      },
      lastUpdatedBy: 'Zuletzt aktualisiert von: ',
      setToDefault: 'Auf systembereitgestellte Werte zurücksetzen',
      updateFuelMatrix: 'Aktualisierung der Brennstoffmatrix',
    },
    parameters: {
      name: 'Name',
      input: 'Eingabe',
      unit: 'Einheit',
      co2Footprint: {
        title: 'Co2-Fußabdruck',
        districtHeating: 'Fernwärme',
        electricity: 'Strom',
        gas: 'Gas',
        units: {
          districtHeating: 'kg/MWh',
          electricity: 'kg/MWh',
          gas: 'kg/MWh',
        },
      },
      investmentCost: {
        title: 'Investitionskosten (CAPEX)',
        ascendingLinesDistributionToApartments: 'Steigleitungen, Verteilung auf Wohnungen',
        constructionCostsApartment: 'Baukosten/Wohnung',
        coolingUnitApartment: 'Kühleinheit/Wohnung',
        coolingUnitAreaFactor: 'Kühleinheit (Flächenfaktor)',
        electricityChangesNewStoveApartment:
          'Änderungen an der Elektrik (wenn das alte System Gas war) neuer Herd/Wohnung',
        heatUnitApartment: 'Heizeinheit/Wohnung',
        units: {
          ascendingLinesDistributionToApartments: '€/Wohnung',
          constructionCostsApartment: '€',
          coolingUnitApartment: '€ (optional)',
          coolingUnitAreaFactor: '€/m²',
          electricityChangesNewStoveApartment: '€',
          heatUnitApartment: '€',
        },
      },
      constructionCosts: {
        title: 'Baukosten',
        buildingInstallationCosts: 'Installationskosten Gebäude',
        districtHeatingConnectionNetFix: 'Anschluss Fernwärmenetz fest',
        districtHeatingConnectionNetVariable: 'Anschluss Fernwärmenetz variabel',
        heatPumpAir: 'Luft-Wärmepumpe',
        heatPumpBuildingPart: 'Wärmepumpe Gebäudeanteil',
        heatPumpGeothermal: 'Geothermische Wärmepumpe',
        units: {
          buildingInstallationCosts: '€/Gebäude (Teil)',
          districtHeatingConnectionNetFix: '€/Objekt',
          districtHeatingConnectionNetVariable: '€/kW Heizbedarf',
          heatPumpAir: '€/kW Heizbedarf',
          heatPumpBuildingPart: '€/Gebäude (Teil)',
          heatPumpGeothermal: '€/kW Heizbedarf',
        },
      },
      thermalParameters: {
        title: 'Thermische Parameter',
        fullLoadHoursHeating: 'Volllaststunden Heizung',
        usefulEnergyHotWater: 'Nutzenergie Warmwasser',
        heatingSystemOutputSafetySurcharge: 'Heizleistung - Sicherheitszuschlag',
        distributionLossForCentralHeating: 'Verteilungsverluste für Zentralheizung',
        annualPerformanceFactorDistrictHeating: 'Jahresarbeitszahl Fernwärme',
        annualPerformanceFactorGasBoiler: 'Jahresarbeitszahl Gasheizung',
        annualPerformanceFactorHeatPumpAir: 'Jahresarbeitszahl Luftwärmepumpe',
        annualPerformanceFactorGeothermalHeatPump: 'Jahresarbeitszahl Erdwärmepumpe',
        annualPerformanceFactorElectricHeating: 'Jahresarbeitszahl Elektroheizung',
        annualPerformanceFactorOilHeating: 'Jahresarbeitszahl Ölheizung',
        annualPerformanceFactorCoalHeating: 'Jahresarbeitszahl Kohleheizung',
        ratioNetFloorAreaToGrossFloorArea: 'Verhältnis Nettogrundfläche zu Bruttogrundfläche',
        units: {
          fullLoadHoursHeating: 'h/a',
          usefulEnergyHotWater: 'kWh/m²/a',
          heatingSystemOutputSafetySurcharge: '',
          distributionLossForCentralHeating: '',
          annualPerformanceFactorDistrictHeating: '',
          annualPerformanceFactorGasBoiler: '',
          annualPerformanceFactorHeatPumpAir: '',
          annualPerformanceFactorGeothermalHeatPump: '',
          annualPerformanceFactorElectricHeating: '',
          annualPerformanceFactorOilHeating: '',
          annualPerformanceFactorCoalHeating: '',
          ratioNetFloorAreaToGrossFloorArea: '',
        },
      },
      renovationCosts: {
        title: 'Sanierungskosten',
        facade: 'Fassade',
        pvSystem: 'PV-System',
        topCeilingInsulation: 'Dämmung der obersten Geschossdecke',
        windows: 'Fenster',
        evChargingPoints: 'EV-Ladepunkte',
        newFlooring: 'Neuer Bodenbelag',
        newFlooringPerUnit: 'Neuer Bodenbelag pro Einheit',
        newKitchen: 'Neue Küche',
        bathroomAndToilet: 'Badezimmer und Toilette',
        wallsAndInteriorDoors: 'Wände und Innentüren',
        electricalInstallation: 'Elektroinstallation',
        electricalInstallationPerUnit: 'Elektroinstallation pro Einheit',
        newCarports: 'Neue Carports',
        newGarages: 'Neue Garagen',
        gardenLandscaping: 'Gartenlandschaftsgestaltung',
        fence: 'Zaun',
        fencePerUnit: 'Zaun pro Einheit',
        domesticVentilation: 'Wohnraumlüftung',
        units: {
          newFlooring: '€/Wohnung',
          newFlooringPerUnit: '€/m2',
          newKitchen: '',
          bathroomAndToilet: '€/m2',
          wallsAndInteriorDoors: '€/m2',
          electricalInstallation: '€/Wohnung',
          electricalInstallationPerUnit: '€/m2',
          newCarports: '€/Carport',
          newGarages: '€/Garage',
          gardenLandscaping: '€/m2',
          domesticVentilation: '€/m2',
          fence: '€/m2',
          fencePerUnit: '€/m2',
          facade: '€/m² geschätzte Fassadenfläche',
          pvSystem: '€/kWp',
          topCeilingInsulation: '€/m² Deckenfläche',
          windows: '€/m² Wohnfläche',
          evChargingPoints: '€/Ladepunkt',
        },
      },
      renovationImpact: {
        title: 'Auswirkungen der Renovierung (Reduzierung des Heizbedarfs)',
        facadeReduction: 'Fassade',
        topCeilingInsulationReduction: 'Dämmung der oberen Decke',
        windowsReduction: 'Fenster',
        domesticVentilation: 'Wohnraumlüftung',
        units: {
          domesticVentilation: '%',
          facadeReduction: '%',
          topCeilingInsulationReduction: '%',
          windowsReduction: '%',
        },
      },
      runningCosts: {
        title: 'Betriebskosten',
        coalDepreciation: 'Kohle - Abschreibung, Rücklagen',
        coalOperation: 'Kohle - Betrieb (Inspektion, Smart Metering usw.)',
        districtHeatingDepreciation: 'Fernwärme - Abschreibung, Rücklagen',
        districtHeatingOperation: 'Fernwärme - Betrieb (Inspektion, Smart Metering usw.)',
        gasDepreciation: 'Gas - Abschreibung, Rücklagen',
        gasOperation: 'Gas - Betrieb (Inspektion, Smart Metering usw.)',
        heatPumpDepreciation: 'Wärmepumpe - Abschreibung, Rücklagen',
        heatPumpOperation: 'Wärmepumpe - Betrieb (Inspektion, Smart Metering usw.)',
        oilDepreciation: 'Öl - Abschreibung, Rücklagen',
        oilOperation: 'Öl - Betrieb (Inspektion, Smart Metering usw.)',
        units: {
          coalDepreciation: 'der Investitionskosten / Wohnung / Jahr',
          coalOperation: '€/Wohnung/Jahr',
          districtHeatingDepreciation: 'der Investitionskosten / Wohnung / Jahr',
          districtHeatingOperation: '€/Wohnung/Jahr',
          gasDepreciation: 'der Investitionskosten / Wohnung / Jahr',
          gasOperation: '€/Wohnung/Jahr',
          heatPumpDepreciation: 'der Investitionskosten / Wohnung / Jahr',
          heatPumpOperation: '€/Wohnung/Jahr',
          oilDepreciation: 'der Investitionskosten / Wohnung / Jahr',
          oilOperation: '€/Wohnung/Jahr',
        },
      },
      financing: {
        title: 'Financing',
        financingDuration: 'Finanzierungsdauer',
        interestRate: 'Zinssatz',
        ownFundsRatio: 'Eigenkapitalquote',
        publicFundingRatio: 'Öffentliche Förderquote',
        vacancyRateAfterRenovation: 'Leerstandsquote nach Renovierung',
        vacancyRateBeforeRenovation: 'Leerstandsquote vor Renovierung',
        units: {
          financingDuration: 'Jahre',
          interestRate: '',
          ownFundsRatio: '',
          publicFundingRatio: '',
          vacancyRateAfterRenovation: '',
          vacancyRateBeforeRenovation: '',
        },
      },
    },
    energyCoefficients: {
      energyStandard: {
        constructionYear: 'Baujahr',
        heatingEnergyDemand: 'Heizenergiebedarf KEA (kWh/m²/a)',
        ageFactor: 'Altersfaktor (der Einfluss von Renovierungen ist bei älteren Gebäuden größer)',
        description: 'Beschreibung',
        title: 'Energiestandard',
        descriptions: {
          [MultiFamilyHouseEnergyStandard.before1918]:
            'Historische Gebäude, meist ohne Dämmung, hohe Energieverluste durch ungedämmte Fassaden, Fenster und Dächer.',
          [MultiFamilyHouseEnergyStandard.between1919And1948]:
            'Zwischenkriegsbauten, oft Ziegelbauweise ohne Dämmung, einige Verbesserungen in der Bauqualität, aber immer noch fehlende Maßnahmen zum Wärmeschutz.',
          [MultiFamilyHouseEnergyStandard.between1949And1978]:
            'Nachkriegsbauten bis zur ersten Wärmeschutzverordnung (WSchVO 1977), wenig bis keine Dämmung, Einfachverglasung, hohe Wärmeverluste bei unsanierten Gebäuden.',
          [MultiFamilyHouseEnergyStandard.between1979And1983]:
            'Einführung der ersten Wärmeschutzverordnung (WSchVO 1977), erste Dämmmaßnahmen an Außenwänden, Decken und Fenstern, Verbesserung der Bauqualität.',
          [MultiFamilyHouseEnergyStandard.between1984And1994]:
            'Verschärfung der Wärmeschutzverordnung (WSchVO 1982), Einführung von Mindestdicken für Dämmstoffe, weitere Verbesserungen bei Fenstern und Dächern.',
          [MultiFamilyHouseEnergyStandard.between1995And2001]:
            'Weitere Verschärfung durch die Wärmeschutzverordnung (WSchVO 1995), besserer Wärmeschutz durch vorgeschriebene Dämmung, thermische Trennung von Bauteilfugen.',
          [MultiFamilyHouseEnergyStandard.between2002And2008]:
            'Einführung der Energieeinsparverordnung (EnEV 2002, EnEV 2007), deutlich verbesserte Dämmung, häufige Nutzung von Wärmedämmverglasung, Wärmerückgewinnung in Lüftungssystemen.',
          [MultiFamilyHouseEnergyStandard.between2009And2015]:
            'Verschärfung der EnEV 2009 und EnEV 2014, strengere Anforderungen an den baulichen Wärmeschutz, moderne Heiztechnik (Brennwerttechnik, Solarthermie).',
          [MultiFamilyHouseEnergyStandard.after2016]:
            'Gültigkeit der EnEV 2016 und ab 2020 das Gebäudeenergiegesetz (GEG), sehr hohe Anforderungen an die Energieeffizienz, Nutzung erneuerbarer Energien, Passivhausstandard bei vielen Neubauten.',
        },
      },
      energy: {
        at: 'ÖSTERREICHISCH',
        de: 'DEUTSCH und alle anderen Länder',
        title: 'Energieklasse',
        efficiencyFactor: 'Gesamtenergieeffizienzfaktor',
        co2: 'CO2 kg/m²/a\nTreibhausgasemission',
        energyClass: 'Energieklasse',
        hwb: 'HWB kWh/m²/a\nSpezifischer Heizwärmebedarf',
        peb: 'PEB kWh/m²/a\nPrimärenergie',
      },
      energyPriceScenarios: {
        title: 'Energiepreisszenarien',
      },
    },
    heatingDemandDataPage: {
      title: 'Heizbedarf-Daten-Uploader',
      instruction: {
        title: 'Anleitung zum Hochladen und Verarbeiten von Dateien',
        description: 'Befolgen Sie diese Richtlinien, um Ihre Dateien korrekt hochzuladen und zu verarbeiten.',
        allowedFileTypes: 'Erlaubte Dateitypen',
        allowedFileTypesText: 'Nur ZIP-Dateien, die CSV- und/oder NC-Dateien enthalten.',
        uploadingRules: 'Hochlade-Regeln',
        uploadingRulesText:
          'Maximal zwei Dateien (z. B. eine ZIP- und eine NC-Datei). Löschen Sie vorherige Dateien, bevor Sie neue hochladen.',
        csvZipProcessing: 'CSV (ZIP) Verarbeitung',
        csvZipProcessingText:
          'Enthält Korrekturfaktoren pro Postleitzahl. Wird in AWS S3 hochgeladen und vom **Server** verarbeitet, anschließend werden die Daten in die Datenbank geladen.',
        ncProcessing: 'NC-Verarbeitung',
        ncProcessingText:
          'Enthält Klimavariablen (hdd, mask, dem, lat, lon, time). Wird in AWS S3 hochgeladen, wodurch **AWS Lambda** ausgelöst wird, das die Daten verarbeitet und die Datenbank aktualisiert.',
        processOverview: 'Prozessübersicht',
        uploadCsvNcfiles: 'CSV-/NC-Dateien hochladen',
        filesStored: 'Dateien werden in AWS S3 gespeichert',
        serverProcesses: 'Server verarbeitet',
        zipCSV: 'ZIP (CSV) → ',
        zipCSVText: 'und aktualisiert die Korrekturfaktoren in der Datenbank',
        nc: 'NC → ',
        AWSLambdaProcesses: 'AWS Lambda verarbeitet',
        ncText: ', teilt nach Koordinaten auf und aktualisiert die Datenbank',
        dataIsReady: 'Daten sind bereit zur Nutzung',
      },
    },
    constructionYears: {
      [MultiFamilyHouseEnergyStandard.before1918]: 'Vor 1918',
      [MultiFamilyHouseEnergyStandard.between1919And1948]: '1919-1948',
      [MultiFamilyHouseEnergyStandard.between1949And1978]: '1949-1978',
      [MultiFamilyHouseEnergyStandard.between1979And1983]: '1979-1983',
      [MultiFamilyHouseEnergyStandard.between1984And1994]: '1984-1994',
      [MultiFamilyHouseEnergyStandard.between1995And2001]: '1995-2001',
      [MultiFamilyHouseEnergyStandard.between2002And2008]: '2002-2008',
      [MultiFamilyHouseEnergyStandard.between2009And2015]: '2009-2015',
      [MultiFamilyHouseEnergyStandard.after2016]: 'Aus 2016',
    },
    pageTitles: {
      login: 'Anmelden',
      initTwoFA: '2FA initialisieren',
      dashboard: 'Dashboard',
      logout: 'Abmelden',
      changeTenant: 'Kunden wechseln',
      forgotPassword: 'Passwort vergessen',
      resetPassword: 'Passwort zurücksetzen',
      userManagement: 'Benutzerverwaltung',
      tenantsManagement: 'Kundenverwaltung',
      tenantManagement: 'Kundenverwaltung',
      redeemInvitation: 'Einladung finalisieren',
      iframesManagement: 'Iframes',
      projectPdfRequests: 'Projekt PDF-Anfragen',
      projects: 'Projekte',
      meinBauApiLogs: 'MeinBau API-Protokolle',
      apiCallLogs: 'API-Aufrufprotokolle',
      auditLogs: '@Audit Logs',
      heatingDemandDataUpload: 'Heizbedarf-Daten',
    },
    planningCategories,
    iframeCategoryConfiguration: {
      table: {
        headers: {
          parent: '@Parent category',
          category: '@Category',
          pricePerUnit: '@Price per unit',
          equipmentTypes: '@Equipment types',
        },
        body: {
          [SolePlateOrBasement.basement]: 'Keller',
          [SolePlateOrBasement.solePlate]: 'Bodenplatte',
        },
      },
      resetConfiguration: '@Reset configuration',
    },
  },
};

export const backendErrors = {
  TWO_FA_ERROR_NOT_ENABLED: '2-Faktor-Authentifizierung ist nicht aktiviert',
  // TWO_FA_CODE_MISSING: 'TWO_FA_CODE_MISSING', - this error shouldn't be i18n-ed
  TWO_FA_ALREADY_ENABLED: '2-Faktor-Authentifizierung ist bereits aktiviert',
  INVALID_TWO_FA_CODE: 'Ungültiges Einmalpasswort ',
  INCORRECT_CREDENTIALS: 'Passwort or Email falsch',
};
