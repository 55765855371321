import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload, faCloudUpload, faFileCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import DndContainerProvider from 'components/dnd/DndContainer';
import useDnd from 'components/dnd/hooks/useDnd';
import FileInput from 'components/inputs/FileInput';
import { useTranslation } from 'react-i18next';
import { getFileExtension, toastTypeUnacceptedFiles, validateFilesSize } from 'components/dnd/DndContainer.helpers';
import { toastErrorMessage } from 'utils/error';
import { FileInstanceType } from 'graphql/types.generated';
import constants from 'constants/constants';
import DroppedFiles from 'components/dnd/DroppedFiles/DroppedFiles';
import {
  GetFilesByInstanceTypeDocument,
  useGetFilesByInstanceTypeQuery,
} from 'graphql/queries/file/generated/GetFilesByInstanceType';
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import Button from 'components/common/Button';
import { useDownloadFileRequestLazyQuery } from 'graphql/queries/file/generated/DownloadFileRequest';
import useDownloadFile from 'components/iframe/hooks/useDownloadFile';
import { useImportNCToDBMutation } from 'graphql/mutations/heatingDegreeDays/generated/ImportNCToDB';
import { useDeleteFileByIdMutation } from 'graphql/mutations/file/generated/DeleteFileById';
import Loading from 'components/common/Loading/Loading';
import { useUpsertCorrectionFactorsMutation } from 'graphql/mutations/correctionFactors/generated/UpsertCorrectionFactors';
import { validateFilesInsideZip } from './validateFilesInsideZip';
import { confirmAlert, ErrorAlert } from 'components/common/Alert';
import UploadInstructions from './Instructions';

const Dropzone = () => {
  const { droppedFiles, onAddNewFiles, onRetryUpload, onUnsetFile } = useDnd({
    tenantId: constants.systemTenantId,
    persistFileRefetchQueries: [
      {
        query: GetFilesByInstanceTypeDocument,
        variables: { instanceType: FileInstanceType.HEATING_DEMAND_DATA },
      },
    ],
  });

  const { t } = useTranslation();

  const [upsertCorrectionFactors, { loading: loadingUpsertCorrectionFactors, error: upsertCorrectionFactorsError }] =
    useUpsertCorrectionFactorsMutation();

  const [proceed, { loading: proceedLoading }] = useImportNCToDBMutation();

  const handleOnAddNewFiles = React.useCallback(
    async ({ files }: { files: File[] }) => {
      const [file] = files;
      if (!file) return;
      try {
        const extension = getFileExtension(file.name);
        if (extension === 'zip') {
          const filteredZip = await validateFilesInsideZip(file);
          if (!filteredZip) return;
          const fileIds = await onAddNewFiles({
            files: [filteredZip],
            payload: { instanceType: FileInstanceType.HEATING_DEMAND_DATA },
          });

          if (fileIds?.length) await upsertCorrectionFactors({ variables: { fileId: fileIds[0] } });
        } else {
          const fileIds = await onAddNewFiles({
            files,
            contentType: 'application/netcdf',
            payload: { instanceType: FileInstanceType.HEATING_DEMAND_DATA },
          });
          const fileId = fileIds?.[0];
          if (fileId) await proceed({ variables: { fileId } });
        }
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [onAddNewFiles, proceed, upsertCorrectionFactors],
  );

  const validateFiles = async (files: File[]) => {
    let validFiles = files;
    validFiles = files.filter(({ name }: File) => {
      return ['nc', 'zip'].includes(getFileExtension(name));
    });
    if (files.length !== validFiles.length) toastTypeUnacceptedFiles(files, validFiles, t);

    validFiles = validateFilesSize(validFiles, 100, t);

    return { isAllValid: !!validFiles.length, validFiles };
  };

  const { data } = useGetFilesByInstanceTypeQuery({
    variables: { instanceType: FileInstanceType.HEATING_DEMAND_DATA },
  });

  const [onDownloadFileClick] = useDownloadFileRequestLazyQuery({ onError: toastErrorMessage });
  const downloadFile = useDownloadFile();

  const onDownloadFileRequest = React.useCallback(
    async (fileId: string) => {
      const { data } = await onDownloadFileClick({ variables: { fileId } });
      if (data?.downloadFileRequest.signedURL) {
        downloadFile(data.downloadFileRequest.signedURL);
      }
    },
    [downloadFile, onDownloadFileClick],
  );

  const [deleteFile, { loading: deleteLoading }] = useDeleteFileByIdMutation();

  const handleDeleteFile = ({ fileId }: { fileId: string }) => {
    if (!fileId) return;
    confirmAlert({
      onConfirm: async () => {
        deleteFile({
          variables: { fileId },
          refetchQueries: [
            {
              query: GetFilesByInstanceTypeDocument,
              variables: { instanceType: FileInstanceType.HEATING_DEMAND_DATA },
            },
          ],
        });
      },
      title: t('files.deleteFileAlert.title'),
      message: t('files.deleteFileAlert.message'),
    });
  };
  const showDndContainer =
    !(data?.getFilesByInstanceType.length && data?.getFilesByInstanceType.length > 1) && !droppedFiles.length;

  const disabledButtons = deleteLoading || proceedLoading || loadingUpsertCorrectionFactors;
  return (
    <div>
      <UploadInstructions />
      {data?.getFilesByInstanceType.length ? <h4 className="mt-4">Uploaded file</h4> : null}
      {upsertCorrectionFactorsError?.message ? (
        <ErrorAlert className="mt-2" message={upsertCorrectionFactorsError?.message} />
      ) : null}
      {data?.getFilesByInstanceType.map(({ name, _id }) => (
        <Row className="align-items-center border rounded p-2 mb-2" key={name}>
          <Col md={10}>{name}</Col>
          <Col>
            <Button disabled={disabledButtons} style={{ marginRight: 10 }} onClick={() => onDownloadFileRequest(_id)}>
              <FontAwesomeIcon icon={faCloudDownload} />
            </Button>
            <Button
              loading={deleteLoading}
              disabled={disabledButtons}
              variant="danger"
              onClick={() => handleDeleteFile({ fileId: _id })}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Col>
        </Row>
      ))}
      {droppedFiles.length ? <DroppedFiles {...{ droppedFiles, onRetryUpload, onUnsetFile }} /> : null}
      {showDndContainer ? (
        <>
          <div className="mb-2 text-end">
            <FileInput
              {...{
                validateFiles,
                onAddNewFiles: handleOnAddNewFiles,
                maxFileSize: 100,
                requiredExtensions: ['nc', 'zip'],
              }}
              className="mt-2"
              icon={faCloudUpload}
              label={t('dnd.uploadBtnLabel')}
            />
          </div>
          <DndContainerProvider
            {...{
              validateFiles,
              droppedFiles,
              onAddNewFiles: handleOnAddNewFiles,
              onRetryUpload,
              onUnsetFile,
              maxFilesCount: 1,
              maxFileSize: 100,
              requiredExtensions: ['nc', 'zip'],
            }}
          >
            <div className="text-center border rounded p-5">
              <FontAwesomeIcon size="3x" icon={faFileCirclePlus} />
              <p className="mt-4">Drop your NetCDF files here — only Network Common Data Form files are allowed!</p>
            </div>
          </DndContainerProvider>
        </>
      ) : null}
      {proceedLoading || loadingUpsertCorrectionFactors ? (
        <Row className="mt-5 text-center">
          <Loading position="center" />
          <span>processing...</span>
        </Row>
      ) : null}
    </div>
  );
};

export default Dropzone;
