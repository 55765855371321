import React, { useState } from 'react';
import { Row, Col, Card, ListGroup, Button, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UploadInstructions = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Button
              variant="link"
              onClick={() => setOpen(!open)}
              aria-controls="instruction-collapse"
              aria-expanded={open}
              className="text-decoration-none"
            >
              📌 {t('heatingDemandDataPage.instruction.title')} {open ? '▲' : '▼'}
            </Button>
          </Card.Header>
          <Collapse in={open}>
            <div id="instruction-collapse">
              <Card.Body>
                <Card.Text>{t('heatingDemandDataPage.instruction.description')}</Card.Text>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <strong>{t('heatingDemandDataPage.instruction.allowedFileTypes')}:</strong>{' '}
                    {t('heatingDemandDataPage.instruction.allowedFileTypesText')}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>{t('heatingDemandDataPage.instruction.uploadingRules')}:</strong>{' '}
                    {t('heatingDemandDataPage.instruction.uploadingRulesText')}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>{t('heatingDemandDataPage.instruction.csvZipProcessing')}:</strong>{' '}
                    {t('heatingDemandDataPage.instruction.csvZipProcessingText')}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>{'heatingDemandDataPage.instruction.ncProcessing'}:</strong>{' '}
                    {t('heatingDemandDataPage.instruction.ncProcessingText')}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>{t('heatingDemandDataPage.instruction.processOverview')}:</strong>
                    <ul>
                      <li>{t('heatingDemandDataPage.instruction.uploadCsvNcfiles')}</li>
                      <li>{t('heatingDemandDataPage.instruction.filesStored')}</li>
                      <li>
                        {t('heatingDemandDataPage.instruction.zipCSV')}
                        <strong>{t('heatingDemandDataPage.instruction.serverProcesses')}</strong>{' '}
                        {t('heatingDemandDataPage.instruction.zipCSVText')}
                      </li>
                      <li>
                        {t('heatingDemandDataPage.instruction.nc')}
                        <strong>{t('heatingDemandDataPage.instruction.AWSLambdaProcesses')}</strong>
                        {t('heatingDemandDataPage.instruction.ncText')}
                      </li>
                      <li>{t('heatingDemandDataPage.instruction.dataIsReady')}</li>
                    </ul>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </div>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
};

export default UploadInstructions;
