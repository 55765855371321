import React, { useCallback, useMemo } from 'react';
import useIframeAssets from '../../../hooks/file/useFiles';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Table from 'components/common/Table/Table';
import { Alert, Stack } from 'react-bootstrap';
import { grabErrorMessage, toastErrorMessage } from 'utils/helpers';
import { Pagination } from 'components/common/Pagination';
import usePagination from 'hooks/usePagination';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload, faCloudUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';
import useDnd from 'components/dnd/hooks/useDnd';
import DndContainerProvider from 'components/dnd/DndContainer';
import DroppedFiles from 'components/dnd/DroppedFiles/DroppedFiles';
import useDeleteFile from 'hooks/file/useDeleteFile';
import constants from 'constants/constants';
import { FilesDocument, FilesQuery } from 'graphql/queries/file/generated/Files';
import useTableObserver from 'hooks/useTableObserver';
import { IframeFilesDocument } from 'graphql/queries/file/generated/IframeFiles';
import Loading from 'components/common/Loading/Loading';
import FileInput from 'components/inputs/FileInput';
import { useDownloadFileRequestLazyQuery } from 'graphql/queries/file/generated/DownloadFileRequest';
import useDownloadFile from '../hooks/useDownloadFile';

interface AssetsRow {
  filename: string;
  actions: { file: First<FilesQuery['files']['items']> };
}

interface IframeAssetsTableParams {
  iframe: IframeQuery['iframe'];
}

const IframeAssetsTable: FC<IframeAssetsTableParams> = ({ iframe }) => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'iframeAssets' });
  const { files, total, error, loading } = useIframeAssets({ currentPage });
  const deleteFile = useDeleteFile();
  const [onDownloadFileClick] = useDownloadFileRequestLazyQuery({ onError: toastErrorMessage });
  const downloadFile = useDownloadFile();
  useTableObserver({ currentPage, total, loading, isError: !!error, changePage });

  const { droppedFiles, onAddNewFiles, onRetryUpload, onUnsetFile } = useDnd({
    iframeId: iframe._id,
    tenantId: iframe.tenantId,
    persistFileRefetchQueries: [
      {
        query: FilesDocument,
        variables: { iframeId: iframe._id, limit: constants.tableRowsCountPerPage, skip: 0 },
      },
      { query: IframeFilesDocument, variables: { iframeId: iframe._id } },
    ],
  });

  const onDownloadFileRequest = useCallback(
    async (fileId: string) => {
      const { data } = await onDownloadFileClick({ variables: { fileId } });
      if (data?.downloadFileRequest.signedURL) {
        downloadFile(data.downloadFileRequest.signedURL);
      }
    },
    [downloadFile, onDownloadFileClick],
  );

  const handleOnAddNewFiles = React.useCallback(
    async ({ files }: { files: File[] }) => {
      const [file] = files;
      if (!file) return;
      try {
        await onAddNewFiles({ files, payload: { instanceId: iframe._id } });
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [iframe._id, onAddNewFiles],
  );

  const columns: ColumnDef<AssetsRow>[] = useMemo(() => {
    return [
      {
        header: t('files.table.headers.filename'),
        accessorKey: 'filename',
        cell: (info) => info.getValue(),
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: (info) => {
          const { file } = info.getValue() as AssetsRow['actions'];
          return (
            <Stack direction="horizontal" gap={2}>
              <Button onClick={() => onDownloadFileRequest(file._id)}>
                <FontAwesomeIcon icon={faCloudDownload} />
              </Button>
              {file.iframeId ? (
                <Button
                  variant="danger"
                  onClick={() => deleteFile({ fileId: file._id, iframeId: file.iframeId as string })}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              ) : null}
            </Stack>
          );
        },
      },
    ];
  }, [deleteFile, onDownloadFileRequest, t]);

  const data: AssetsRow[] = useMemo(() => {
    return files.map((file) => ({ filename: file.name, actions: { file } }));
  }, [files]);

  if (loading) return <Loading />;
  if (error) {
    return <Alert variant="danger">{grabErrorMessage(error)}</Alert>;
  }

  return (
    <>
      <div className="mb-2 text-end">
        <FileInput {...{ onAddNewFiles, maxFileSize: 100 }} icon={faCloudUpload} label={t('dnd.uploadBtnLabel')} />
      </div>
      {droppedFiles.length ? <DroppedFiles {...{ droppedFiles, onRetryUpload, onUnsetFile }} /> : null}
      <DndContainerProvider
        {...{
          droppedFiles,
          onAddNewFiles: handleOnAddNewFiles,
          onRetryUpload,
          onUnsetFile,
          maxFilesCount: 10,
          canDropAlways: !data.length,
        }}
      >
        {data.length ? (
          <>
            <Table columns={columns} data={data} loading={loading} emptyText="iframeAssets.modal.noFilesUploaded" />
            <Pagination {...{ changePage, currentPage, total }} />
          </>
        ) : null}
      </DndContainerProvider>
    </>
  );
};

export default IframeAssetsTable;
