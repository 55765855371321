import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from '../../../fragments/file/generated/FileFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilesQueryVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
}>;

export type FilesQuery = {
  __typename?: 'Query';
  files: {
    __typename?: 'PaginatedFileResponse';
    total: number;
    items: Array<{
      __typename?: 'File';
      _id: string;
      name: string;
      iframeId?: string | null;
      tenantId: string;
      sizeBytes: number;
      createdAt: any;
    }>;
  };
};

export const FilesDocument = gql`
  query Files($iframeId: ID!, $limit: Int!, $skip: Int!) {
    files(iframeId: $iframeId, limit: $limit, skip: $skip) {
      total
      items {
        ...File
      }
    }
  }
  ${FileFragmentDoc}
`;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFilesQuery(baseOptions: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
}
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
}
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export function refetchFilesQuery(variables: FilesQueryVariables) {
  return { query: FilesDocument, variables: variables };
}
