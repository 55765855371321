import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertCorrectionFactorsMutationVariables = SchemaTypes.Exact<{
  fileId: SchemaTypes.Scalars['ID']['input'];
}>;

export type UpsertCorrectionFactorsMutation = { __typename?: 'Mutation'; upsertCorrectionFactors: boolean };

export const UpsertCorrectionFactorsDocument = gql`
  mutation UpsertCorrectionFactors($fileId: ID!) {
    upsertCorrectionFactors(fileId: $fileId)
  }
`;
export type UpsertCorrectionFactorsMutationFn = Apollo.MutationFunction<
  UpsertCorrectionFactorsMutation,
  UpsertCorrectionFactorsMutationVariables
>;

/**
 * __useUpsertCorrectionFactorsMutation__
 *
 * To run a mutation, you first call `useUpsertCorrectionFactorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCorrectionFactorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCorrectionFactorsMutation, { data, loading, error }] = useUpsertCorrectionFactorsMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useUpsertCorrectionFactorsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertCorrectionFactorsMutation, UpsertCorrectionFactorsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertCorrectionFactorsMutation, UpsertCorrectionFactorsMutationVariables>(
    UpsertCorrectionFactorsDocument,
    options,
  );
}
export type UpsertCorrectionFactorsMutationHookResult = ReturnType<typeof useUpsertCorrectionFactorsMutation>;
export type UpsertCorrectionFactorsMutationResult = Apollo.MutationResult<UpsertCorrectionFactorsMutation>;
export type UpsertCorrectionFactorsMutationOptions = Apollo.BaseMutationOptions<
  UpsertCorrectionFactorsMutation,
  UpsertCorrectionFactorsMutationVariables
>;
