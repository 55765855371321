import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import PageTitle from 'components/common/PageTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from './Dropzone';

const HeatingDemandDataUpload = () => {
  const { t } = useTranslation();
  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.heatingDemandDataUpload') }]} />
      <PageTitle>{t('heatingDemandDataPage.title')}</PageTitle>
      <Dropzone />
    </>
  );
};

export default HeatingDemandDataUpload;
