import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFileByIdMutationVariables = SchemaTypes.Exact<{
  fileId: SchemaTypes.Scalars['ID']['input'];
}>;

export type DeleteFileByIdMutation = { __typename?: 'Mutation'; deleteFileById: boolean };

export const DeleteFileByIdDocument = gql`
  mutation DeleteFileById($fileId: ID!) {
    deleteFileById(fileId: $fileId)
  }
`;
export type DeleteFileByIdMutationFn = Apollo.MutationFunction<DeleteFileByIdMutation, DeleteFileByIdMutationVariables>;

/**
 * __useDeleteFileByIdMutation__
 *
 * To run a mutation, you first call `useDeleteFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileByIdMutation, { data, loading, error }] = useDeleteFileByIdMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteFileByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileByIdMutation, DeleteFileByIdMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileByIdMutation, DeleteFileByIdMutationVariables>(DeleteFileByIdDocument, options);
}
export type DeleteFileByIdMutationHookResult = ReturnType<typeof useDeleteFileByIdMutation>;
export type DeleteFileByIdMutationResult = Apollo.MutationResult<DeleteFileByIdMutation>;
export type DeleteFileByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileByIdMutation,
  DeleteFileByIdMutationVariables
>;
