import { FileInput } from 'graphql/types.generated';
import { v4 as uuidv4 } from 'uuid';

export const allowedFiles = [
  { extension: 'png', mimeType: 'image/png' },
  { extension: 'svg', mimeType: 'image/svg+xml' },
  { extension: 'tiff', mimeType: 'image/tiff' },
  { extension: 'jpg', mimeType: 'image/jpeg' },
  { extension: 'jpeg', mimeType: 'image/jpeg' },
  { extension: 'ico', mimeType: 'image/vnd.microsoft.icon' },
];

export const allowedExtensions = allowedFiles.map(({ extension }) => extension);
export const allowedMimeTypes = allowedFiles.map(({ mimeType }) => mimeType);

export type FilePayload = Pick<FileInput, 'instanceId' | 'instanceType'>;
export interface EnhancedFile extends FilePayload {
  webClientFileId: string;
  uploadPercentage: number;
  file: File;
  uploadStatus: UploadStatus;
  controller: AbortController;
}

export enum UploadStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  DEFAULT = '',
}

export const enhanceFiles = (files: File[], payload?: FilePayload): EnhancedFile[] => {
  return files.map((file: File) => {
    const controller = new AbortController();
    return {
      webClientFileId: uuidv4(),
      uploadPercentage: 0,
      uploadStatus: UploadStatus.DEFAULT,
      file,
      controller,
      ...payload,
    };
  });
};

export const cancelAllUploadRequests = (files: EnhancedFile[]) => {
  files.forEach((file) => {
    file.controller.abort();
  });
};

export const enhancedFilesToUploadReady = (enhancedFiles: EnhancedFile[], contentType?: string): FileInput[] => {
  return enhancedFiles.map(({ webClientFileId, file, instanceId, instanceType }: EnhancedFile) => ({
    webClientFileId,
    filename: file.name,
    sizeBytes: file.size,
    contentType: (file.type || contentType) as string,
    instanceId,
    instanceType,
  }));
};

export const updateFilesUploadStatus = (
  enhancedFiles: EnhancedFile[],
  targetFileIds: string[],
  uploadStatus: UploadStatus,
) => {
  const updatedFiles = enhancedFiles.map((file) => {
    const { webClientFileId } = file;
    if (targetFileIds.includes(webClientFileId)) {
      file.uploadStatus = uploadStatus;
    }
    return file;
  });
  return updatedFiles;
};
