import JSZip from 'jszip';
import { toastErrorMessage } from 'utils/error';

export const validateFilesInsideZip = async (zipFile: File) => {
  const zip = new JSZip();
  const zipContent = await zip.loadAsync(zipFile);

  const newZip = new JSZip();
  let hasCsvFiles = false;

  for (const [fileName, zipEntry] of Object.entries(zipContent.files)) {
    if (!zipEntry.dir && fileName.endsWith('.csv') && !fileName.includes('__MACOSX/')) {
      const fileData = await zipEntry.async('blob');
      newZip.file(fileName, fileData, { binary: true });
      hasCsvFiles = true;
    }
  }

  if (!hasCsvFiles) {
    toastErrorMessage('No CSV files found in the ZIP.');
    return null;
  }

  const newZipBlob = await newZip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
  });

  return new File([newZipBlob], zipFile.name, { type: 'application/zip' });
};
