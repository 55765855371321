import React, { useMemo } from 'react';
import { routePaths } from './routes';
import { Navigate, Route, Routes, generatePath } from 'react-router-dom';
import AppLayout from 'components/layouts/AppLayout';
import { MeQuery } from 'graphql/queries/user/generated/Me';
import Logout from 'pages/auth/Logout';
import InitTwoFAPage from 'pages/auth/InitTwoFAPage';
import Dashboard from 'pages/Dashboard';
import UserManagementPage from 'pages/userManagement/UserManagementPage';
import TenantsManagementPage from 'pages/tenantManagement/TenantsManagementPage';
import TenantPage from 'pages/tenant/TenantPage';
import { Roles } from 'graphql/types.generated';
import IframeManagementPage from 'pages/iframeManagement/IframeManagementPage';
import IframePage from 'pages/iframe/IframePage';
import ChangeTenantPage from 'pages/tenant/ChangeTenant';
import { ctxTenantIdVar } from 'graphql/apollo';
import { useReactiveVar } from '@apollo/client';
import DashboardTenantPage from 'pages/tenant/DashboardTenantPage';
import TenantIframeManagementPage from 'pages/iframeManagement/TenantIframeManagementPage';
import TenantUserManagementPage from 'pages/userManagement/TenantUserManagementPage';
import CreateIframePage from 'pages/iframe/CreateIframePage';
import UpdateIframePage from 'pages/iframe/UpdateIframePage';
import ProjectPdfRequestsPage from 'pages/projectPdfRequests/ProjectPdfRequestsPage';
import ProjectsPage from 'pages/project/ProjectsPage';
import MeinBauApiLogsPage from 'pages/meinBauApiLogs/MeinBauApiLogsPages';
import MeinBauApiLogPage from 'pages/meinBauApiLogs/MeinBauApiLogPage';
import ApiCallLogsPage from 'pages/apiCallLogs/ApiCallLogsPage';
import ApiCallLogPage from 'pages/apiCallLogs/ApiCallLogPage';
import TenantMeinBauApiLogsPage from 'pages/meinBauApiLogs/TenantMeinBauApiLogsPage';
import AuditLogsPage from 'pages/auditLogs/AuditLogsPage';
import AuditLogPage from 'pages/auditLog/AuditLogPage';
import HeatingDemandDataUpload from 'pages/heatingDemandDataUpload/HeatingDemandDataUpload';

export const appRoutes = [
  {
    path: routePaths.dashboard,
    component: Dashboard,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.tenantDashboard,
    component: DashboardTenantPage,
    requiredRoles: [Roles.TENANT_ADMIN],
  },
  {
    path: routePaths.logout,
    component: Logout,
  },
  {
    path: routePaths.userManagement,
    component: UserManagementPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.tenantsManagement,
    component: TenantsManagementPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.tenantManagement,
    component: TenantPage,
  },
  {
    path: routePaths.iframesManagement,
    component: IframeManagementPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.createIframe,
    component: CreateIframePage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.updateIframe,
    component: UpdateIframePage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.projectPdfRequests,
    component: ProjectPdfRequestsPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.projects,
    component: ProjectsPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.meinBauApiLogs,
    component: MeinBauApiLogsPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.tenantMeinBauApiLogs,
    component: TenantMeinBauApiLogsPage,
    requiredRoles: [Roles.TENANT_ADMIN],
  },
  {
    path: routePaths.meinBauApiLog,
    component: MeinBauApiLogPage,
    requiredRoles: [Roles.MEINBAU_ADMIN, Roles.TENANT_ADMIN],
  },
  { path: routePaths.apiCallLogs, component: ApiCallLogsPage, requiredRoles: [Roles.MEINBAU_ADMIN] },
  { path: routePaths.apiCallLog, component: ApiCallLogPage, requiredRoles: [Roles.MEINBAU_ADMIN] },
  {
    path: routePaths.tenantIframesManagement,
    component: TenantIframeManagementPage,
    requiredRoles: [Roles.TENANT_ADMIN],
  },
  {
    path: routePaths.tenantIframe,
    component: IframePage,
  },
  {
    path: routePaths.changeTenant,
    component: ChangeTenantPage,
    requiredTenantsNumber: 2,
    requiredRoles: [Roles.TENANT_ADMIN],
  },
  {
    path: routePaths.auditLogs,
    component: AuditLogsPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.auditLog,
    component: AuditLogPage,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
  {
    path: routePaths.heatingDemandDataUpload,
    component: HeatingDemandDataUpload,
    requiredRoles: [Roles.MEINBAU_ADMIN],
  },
];

const AppRouter = ({ me }: { me: MeQuery['me'] }) => {
  const ctxTenantId = useReactiveVar(ctxTenantIdVar);
  const activeRolesCount = me.activeRoles.length || 0;
  const forceEnableTwoFA = !me?.isTwoFAEnabled;

  if (me && activeRolesCount === 1 && !ctxTenantId) {
    const userRole = me.activeRoles[0];
    if (userRole.tenantId) ctxTenantIdVar(userRole.tenantId);
  }

  const fallbackPath = useMemo(() => {
    if (me.role !== Roles.MEINBAU_ADMIN) {
      return generatePath(routePaths.tenantDashboard, { tenantId: me.activeRoles[0]?.tenantId || '' });
    }
    return routePaths.dashboard;
  }, [me.activeRoles, me.role]);

  if (forceEnableTwoFA) {
    return (
      <AppLayout hideSidebar>
        <Routes>
          <Route path={routePaths.logout} element={<Logout />} />
          <Route path="*" element={<InitTwoFAPage />} />
        </Routes>
      </AppLayout>
    );
  }

  if (activeRolesCount > 1 && !ctxTenantId) {
    return (
      <AppLayout hideSidebar>
        <Routes>
          <Route path={routePaths.logout} element={<Logout />} />
          <Route path={routePaths.changeTenant} element={<ChangeTenantPage />} />
          <Route path="*" element={<ChangeTenantPage />} />
        </Routes>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <Routes>
        {appRoutes.map(({ path, component: C, requiredTenantsNumber, requiredRoles }) => {
          if (requiredRoles && me?.role && !requiredRoles.includes(me.role)) return null;
          if (requiredTenantsNumber && (me?.activeRoles?.length || 1) < requiredTenantsNumber) {
            return null;
          }
          return <Route key={path} path={path} element={<C />} />;
        })}
        <Route path="*" element={<Navigate to={fallbackPath} />} />
      </Routes>
    </AppLayout>
  );
};

export default AppRouter;
