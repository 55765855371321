import React, { useCallback, useMemo } from 'react';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import { DndProvider, DropTargetMonitor, useDrop } from 'react-dnd';
import { EnhancedFile, allowedExtensions } from './DroppedFiles.helpers';
import { Col, Form as RForm } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { intersection } from 'lodash';
import { filterTypeAcceptableFiles, validateFilesSize, validateMimeTypes } from './DndContainer.helpers';
import cn from 'classnames';
import { useHandleChangeFileInput } from './hooks/useHandleChangeFileInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import s from './DndContainer.module.sass';

export const useGetExtensionsByRole = ({
  extensions,
  requiredExtensions,
}: {
  extensions?: string[];
  requiredExtensions?: string[];
}) => {
  if (requiredExtensions?.length) return requiredExtensions;
  if (!extensions?.length) return allowedExtensions;
  return intersection(allowedExtensions, extensions);
};

export type OnAddNewFiles = (props: { files: File[] }) => Promise<string[] | void> | string[] | void;

export interface IDndBoxProps {
  name?: string;
  children?: React.ReactNode;
  droppedFiles?: EnhancedFile[];
  onAddNewFiles?: OnAddNewFiles;
  extensions?: string[];
  canDropAlways?: boolean;
  maxFilesCount?: number;
  maxFileSize?: number;
  requiredExtensions?: string[];
  validateFiles?: (Files: File[]) => Promise<{ validFiles: File[]; isAllValid: boolean }>;
}

const DndBox: FC<IDndBoxProps> = ({
  children,
  droppedFiles = [],
  onAddNewFiles,
  extensions,
  canDropAlways,
  maxFilesCount,
  maxFileSize = 1,
  name = 'file',
  requiredExtensions,
  validateFiles,
}) => {
  const availableExtensions = useGetExtensionsByRole({ extensions, requiredExtensions });

  const accept = useMemo(
    () => availableExtensions.map((extension) => `.${extension}`).join(', '),
    [availableExtensions],
  );
  const { t } = useTranslation();

  const handleChange = useHandleChangeFileInput({ onAddNewFiles, extensions: availableExtensions, maxFileSize });

  const onDrop = useCallback<OnAddNewFiles>(
    async ({ files }) => {
      if (maxFilesCount && files.length > maxFilesCount) {
        toast.error(t('dnd.tooManyFiles', { maxFilesCount }));
        return;
      }

      let isAllValid: boolean | undefined;
      let validFiles = files;

      if (validateFiles) {
        const result = await validateFiles(files);
        isAllValid = result.isAllValid;
        validFiles = result.validFiles;
      } else {
        validFiles = filterTypeAcceptableFiles({ files, extensions: availableExtensions, t });

        validFiles = validateFilesSize(validFiles, maxFileSize, t);

        isAllValid = await validateMimeTypes(validFiles);
      }

      if (!isAllValid) {
        toast.error(t('dmsTenant.invalidOriginalFileType'));
        return;
      }
      if (typeof onAddNewFiles === 'function') {
        await onAddNewFiles({ files: validFiles });
      }
    },
    [maxFilesCount, validateFiles, onAddNewFiles, t, availableExtensions, maxFileSize],
  );

  const handleDroppedFiles = useCallback(
    async (item: { files: File[] }) => {
      if (!item) return;
      return onDrop({ files: item.files });
    },
    [onDrop],
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: handleDroppedFiles,
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  );

  return (
    <Col xs={12}>
      <div
        className="position-relative"
        style={canDrop || canDropAlways ? { minHeight: '40vh' } : undefined}
        ref={drop}
      >
        {canDrop || canDropAlways ? (
          <RForm.Label htmlFor={name} className={cn(s.dropzone, isOver ? s.fileIsOver : '')}>
            <RForm.Control
              id={name}
              name={name}
              className="d-none"
              type="file"
              size="sm"
              accept={accept}
              onChange={handleChange}
            />
            <FontAwesomeIcon icon={faCloudUpload} className="text-primary" size="3x" />
            <p className={s.dropzoneText}>{t(isOver ? 'dnd.releaseToUpload' : 'dnd.dropFileHere')}</p>
            <p>
              {t('dnd.acceptableFileTypes', {
                extensions: availableExtensions.map((ext) => ext.toUpperCase()).join(', '),
              })}
            </p>
            <p>{t('dnd.maxFileSize', { size: maxFileSize })}</p>
          </RForm.Label>
        ) : null}
        {children}
      </div>
    </Col>
  );
};

const DndContainerProvider: FC<IDndBoxProps> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DndBox {...props} />
    </DndProvider>
  );
};

export default DndContainerProvider;
