import React, { ChangeEvent, useCallback, useMemo, FC } from 'react';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Stack } from 'react-bootstrap';
import s from './Switch.module.sass';

interface ISwitchProps {
  field: FieldInputProps<boolean>;
  form: FormikProps<string>;
  label: string | React.ReactElement;
  className?: string;
  type?: 'default' | 'danger';
}

const labelClasses = {
  default: s.defaultLabel,
  danger: s.dangerLabel,
};

const Switch: FC<ISwitchProps> = ({ field, form, label, className, type = 'default' }) => {
  const { name, value, onBlur } = field;
  const { touched, errors, setFieldValue } = form;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, e.target.checked);
    },
    [name, setFieldValue],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group className={cn(className ?? 'mb-3')}>
      <Stack direction="horizontal" gap={1}>
        <Form.Check id={name} type="switch" checked={value} {...{ name, onBlur, onChange: handleChange }} />
        <Form.Label className={cn(labelClasses[type])} htmlFor={name}>
          {typeof label === 'string' ? t(label) : label}
        </Form.Label>
      </Stack>
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default Switch;
