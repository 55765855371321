import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFilesByInstanceTypeQueryVariables = SchemaTypes.Exact<{
  instanceType: SchemaTypes.FileInstanceType;
}>;

export type GetFilesByInstanceTypeQuery = {
  __typename?: 'Query';
  getFilesByInstanceType: Array<{ __typename?: 'BriefFileInfo'; _id: string; name: string }>;
};

export const GetFilesByInstanceTypeDocument = gql`
  query GetFilesByInstanceType($instanceType: FileInstanceType!) {
    getFilesByInstanceType(instanceType: $instanceType) {
      _id
      name
    }
  }
`;

/**
 * __useGetFilesByInstanceTypeQuery__
 *
 * To run a query within a React component, call `useGetFilesByInstanceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesByInstanceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesByInstanceTypeQuery({
 *   variables: {
 *      instanceType: // value for 'instanceType'
 *   },
 * });
 */
export function useGetFilesByInstanceTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetFilesByInstanceTypeQuery, GetFilesByInstanceTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilesByInstanceTypeQuery, GetFilesByInstanceTypeQueryVariables>(
    GetFilesByInstanceTypeDocument,
    options,
  );
}
export function useGetFilesByInstanceTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFilesByInstanceTypeQuery, GetFilesByInstanceTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilesByInstanceTypeQuery, GetFilesByInstanceTypeQueryVariables>(
    GetFilesByInstanceTypeDocument,
    options,
  );
}
export type GetFilesByInstanceTypeQueryHookResult = ReturnType<typeof useGetFilesByInstanceTypeQuery>;
export type GetFilesByInstanceTypeLazyQueryHookResult = ReturnType<typeof useGetFilesByInstanceTypeLazyQuery>;
export type GetFilesByInstanceTypeQueryResult = Apollo.QueryResult<
  GetFilesByInstanceTypeQuery,
  GetFilesByInstanceTypeQueryVariables
>;
export function refetchGetFilesByInstanceTypeQuery(variables: GetFilesByInstanceTypeQueryVariables) {
  return { query: GetFilesByInstanceTypeDocument, variables: variables };
}
